// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase';

// Add the Firebase services that you want to use
import "firebase/auth";

const configProduction = {
  apiKey: "AIzaSyC9HDD2nHhWky88uVwqYA_mskNW_iYDi2M",
    authDomain: "grupo-axo-ecommerce.firebaseapp.com",
    databaseURL: "https://grupo-axo-ecommerce-default-rtdb.firebaseio.com",
    projectId: "grupo-axo-ecommerce",
    storageBucket: "grupo-axo-ecommerce.appspot.com",
    messagingSenderId: "8363852839",
    appId: "1:8363852839:web:3ff09ad2ac8825fac5fa02",
    measurementId: "G-ZFF38XR8TC"
};

const firebaseConfig= firebase.initializeApp(configProduction);

export default firebaseConfig