import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import firebaseConfig from '../../FirebaseConfig';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  progress: {
    display: 'flex',
    margin:50,
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
export default function ItemsListComponent(props) {
  const classes = useStyles();
  const [valueReviewsList, setValueReviewsList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const {
    idBrand,
    idProduct
  } = props;

  useEffect(()=>{
    function GetReviews(){
      /*let getReviews = firebaseConfig.functions().httpsCallable('getApprovedReviewsByProduct');
      getReviews({
          idBrand:    idBrand,
          idProduct:  idProduct,
          nextKey:    null,
          limit:      10
      }).then((result)=> {
        setLoading(false)
        setValueReviewsList(result.data.reviews)
      }).catch((error)=> {
          console.log(error);   
      })*/
      fetch("https://reviews-axo.wallia.io/getApprovedReviewsByProduct",{
        mode: 'cors', // no-cors, *cors, same-origin
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
        },
        body: JSON.stringify({
          idBrand:    idBrand,
          idProduct:  idProduct,
          nextKey:    null,
          limit:      10
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setLoading(false)
          setValueReviewsList(result.reviews)
        },(error) => {
          console.log(error)
      })
    }

    GetReviews();
  },[])

  return (
    <div>  
      {!loading?
        <>
          {valueReviewsList.length>0?
            <div className={classes.paper}>
              <Typography
                component={'span'}
                variant="body2"
                style = {{fontWeight:'bolder',fontSize:'24px',marginLeft:'10px'}}
                color="textPrimary">
                {'Last comments'}
              </Typography>
              <List className={classes.root}>
                {valueReviewsList.map((element,index)=>{
                    return(
                      <div key={index}>
                      {element.message?
                        <div key={index}>
                          <ListItem key={index} alignItems="flex-start">
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography
                                component={'p'}
                                variant="body1"
                                className={classes.inline}
                                style={{fontWeight:'bolder',fontSize:'16px',marginLeft:'4px'}}
                                color="textPrimary"
                              >
                                {element.userName}
                              </Typography>  
                            </Grid>
                            <Grid item xs={12}>
                              <Rating name="read-only" size="small" value={parseInt(element.score)} readOnly />
                            </Grid>
                            
                            <Grid item xs={12}>
                              <Typography
                                component={'p'}
                                variant="body2"
                                className={classes.inline}
                                style={{fontSize:'16px',marginLeft:'4px'}}
                                color="textPrimary"
                              >
                                {element.message}
                              </Typography>  
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                component={'p'}
                                variant="caption"
                                className={classes.inline}
                                style={{fontSize:'12px',marginLeft:'4px'}}
                              >
                                {new Date(element.date).toLocaleDateString("es-MX",options)}
                              </Typography>
                            </Grid>
                            </Grid>
                          </ListItem>
                          <Divider key={'divider'+index} component="li" />
                        </div>
                        :<></>
                      }
                      </div>
                    )
                })}        
              </List>
            </div>
            :
            <div className={classes.paper}>
              <Typography
                component={'span'}
                variant="body2"
                style = {{fontWeight:'bolder',fontSize:'24px',marginLeft:'10px'}}
                color="textPrimary">
                {'Aun no hay comentarios'}
              </Typography>       
            </div>
          }
        </>
        :
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      } 
    </div>
  );
}