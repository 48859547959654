import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InputIcon from '@material-ui/icons/Input';
import firebaseConfig from '../../../../FirebaseConfig';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    textTransform: 'none',
    fontFamily: "'Roboto Condensed', sans-serif",
    color: '#fff',
    fontWeight: 700,
    fontFamilySecondary: "'Roboto Condensed', sans-serif"
  },
  root: {
    boxShadow: 'none',
    marginLeft:0,
    paddingLeft:0
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, on, ...rest } = props;
  const classes = useStyles();
  const [production, setProduction] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setProduction(!production);
    on(!production)
  };
  

  return (
    <AppBar
      style={{ background: '#252525' }}
      {...rest}
      className={clsx(classes.root, className)}>
      <Toolbar style={{marginLeft:0}}>
       <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h6" className={classes.title}>
         DEVELOPER PORTAL
        </Typography>
        <div className={classes.flexGrow} />
        {/*<FormControlLabel
          control={
            <Switch
              checked={production}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="production ON"
        />*/}
          <Typography variant="h6" className={classes.title}>
            Production
          </Typography>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => firebaseConfig.auth().signOut()}>
            <InputIcon />
          </IconButton>    
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
