
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import firebaseConfig from '../../FirebaseConfig';
import AccordionComponent from './components/AccordionComponent';
import ReactJson from 'react-json-view'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin:theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'#252525',
    maxHeight:'200px',
    overflow: 'auto',
    color:'#00FF6B',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const addReviewDoc = {
  description:`Este metodo le permite agregar una review a un producto especifico.
  La función recibe un identificador de brand, de producto y usuario. 
  Cuando una review es agregada el valor por default de [status] es 'pending'. 
  El valor por default de [score] sera siempre como minimo 1 y 5 como maximo. 
  El parametro [userName] corresponde al nombre de usuario respecto al identificador de usuario proporcionado.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, limit, idUser, score, userName ]`,
  paramsOpcional:"Parametros Opcionales:[ message, deviceInfo ]",
  implement1:`
  let addReview = firebaseConfig.functions().httpsCallable('addReview');  
  const response = await addReview({
      idBrand: 'guess-reviews,
      idProduct: 'S-232F56',
      idUser: '-HJKIKKL023JK',
      userName: 'Ramon Jeferson',
      score: 1,
      message: 'Very bad product :(',
      deviceInfo: {
          osVersion:      'Android',
          buildVersion:   '6.0',
      }
  })
  console.log(response)
  `,
  implement2:`
    POST https://reviews-axo.wallia.io/addReview
    HEADER Authorization:'bearer [acessToken]'
    BODY {
        idBrand: 'guess-reviews,
        idProduct: 'S-232F56',
        idUser: '-HJKIKKL023JK',
        userName: 'Ramon Jeferson',
        score: 1,
        message: 'Very bad product :(',
        deviceInfo: {
            osVersion:      'Android',
            buildVersion:   '6.0',
        }
    }
  `
}

const getReviewsByProductDoc = {
  description:`Este metodo le permite obtener una lista de reviews respecto a un producto especifico.
  La función recibe un identificador de brand, de producto, un limite de resultados por pagina y un llave de indexación.
  El parametro [limit] sera la cantidad de reviews que el usuario espera recibir por pagina. 
  Esta función no hace distinción entre los estados de cada review [approved, notapproved, pending]. 
  Inicialmente no se requiere proporcionar un llave de indexación, de esta manera se garantiza que podra obtener las ultimas reviews agregadas al producto en la primera llamada,
  usted debera encargase de enviar el parametro [lastKey] para poder obtener el siguiente lote mas reciente de reviews, este parametro sera retornado como [nextKey] tras la primera llamada.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, limit,]`,
  paramsOpcional:"Parametros Opcionales:[ lastKey ]",
  implement1:`
  lastKey=null
  let getReviewsByProduct = firebaseConfig.functions().httpsCallable('getReviewsByProduct');     
  const response = await getReviewsByProduct({
      idBrand: 'calvin-klein-reviews',
      idProduct: 'S-232F56'
      limit:5,
      lastKey: lastKey
  })
  lastKey=result.data.nextKey
  console.log(response.data)
  `,implement2:`
    POST https://reviews-axo.wallia.io/getReviewsByProduct
    HEADER Authorization:'bearer [acessToken]'
    BODY {
      idBrand: 'calvin-klein-reviews',
      idProduct: 'S-232F56'
      limit:5,
      lastKey: lastKey
    }
  `}

const getNotApprovedReviewsByProductDoc = {
  description:`Este metodo le permite obtener una lista de reviews no aprobados respecto a un producto especifico.
  La función recibe un identificador de brand, de producto, un limite de resultados por pagina y un llave de indexación.
  El parametro [limit] sera la cantidad de reviews que el usuario espera recibir por pagina. 
  Esta función hace distinción entre los estados de cada review identificados como no aprobados [notapproved]. 
  Inicialmente no se requiere proporcionar un llave de indexación, de esta manera se garantiza que podra obtener las ultimas reviews agregadas al producto en la primera llamada,
  usted debera encargase de enviar el parametro [lastKey] para poder obtener el siguiente lote mas reciente de reviews, este parametro sera retornado como [nextKey] tras la primera llamada.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, limit]`,
  paramsOpcional:"Parametros Opcionales:[ lastKey ]",
  implement1:`
  let lastKey=null
  let getNotApprovedReviewsByProduct = firebaseConfig.functions().httpsCallable('getNotApprovedReviewsByProduct');     
  const response = await getNotApprovedReviewsByProduct({
      idBrand: 'calvin-klein-reviews',
      idProduct: 'S-232F56'
      limit:5,
      lastKey: lastKey
  })
    lastKey=result.data.nextKey
    console.log(response)
  `,implement2:`
  POST https://reviews-axo.wallia.io/getNotApprovedReviewsByProduct
  HEADER Authorization:'bearer [acessToken]'
  BODY {
    idBrand: 'calvin-klein-reviews',
    idProduct: 'S-232F56'
    limit:5,
    lastKey: lastKey
  }
`}

const getApprovedReviewsByProductDoc = {
  description:`Este metodo le permite obtener una lista de reviews aprobados respecto a un producto especifico.
  La función recibe un identificador de brand, de producto, un limite de resultados por pagina y un llave de indexación.
  El parametro [limit] sera la cantidad de reviews que el usuario espera recibir por pagina. 
  Esta función hace distinción entre los estados de cada review identificados como aprobados [approved]. 
  Inicialmente no se requiere proporcionar un llave de indexación, de esta manera se garantiza que podra obtener las ultimas reviews agregadas al producto en la primera llamada,
  usted debera encargase de enviar el parametro [lastKey] para poder obtener el siguiente lote mas reciente de reviews, este parametro sera retornado como [nextKey] tras la primera llamada.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, limit,]`,
  paramsOpcional:"Parametros Opcionales:[ lastKey ]",
  implement1:`
  lastKey=null
  let getApprovedReviewsByProduct = firebaseConfig.functions().httpsCallable('getApprovedReviewsByProduct');     
  const response = await getApprovedReviewsByProduct({
      idBrand: 'calvin-klein-reviews',
      idProduct: 'S-232F56'
      limit:5,
      lastKey: lastKey
  })
  lastKey=result.data.nextKey
  console.log(response.data)
  `,implement2:`
  POST https://reviews-axo.wallia.io/getApprovedReviewsByProduct
  HEADER Authorization:'bearer [acessToken]'
  BODY {
    idBrand: 'calvin-klein-reviews',
    idProduct: 'S-232F56'
    limit:5,
    lastKey: lastKey
  }
`}

const getPendingReviewsByProductDoc= {
  description:`Este metodo le permite obtener una lista de reviews pendientes respecto a un producto especifico.
  La función recibe un identificador de brand, de producto, un limite de resultados por pagina y un llave de indexación.
  El parametro [limit] sera la cantidad de reviews que el usuario espera recibir por pagina. 
  Esta función hace distinción entre los estados de cada review identificados como aprobados [pendings]. 
  Inicialmente no se requiere proporcionar un llave de indexación, de esta manera se garantiza que podra obtener las ultimas reviews agregadas al producto en la primera llamada,
  usted debera encargase de enviar el parametro [lastKey] para poder obtener el siguiente lote mas reciente de reviews, este parametro sera retornado como [nextKey] tras la primera llamada.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, limit,]`,
  paramsOpcional:"Parametros Opcionales:[ lastKey ]",
  implement1:`
  lastKey=null
  let getPendingsReviewsByProduct = firebaseConfig.functions().httpsCallable('getPendingsReviewsByProduct');     
  const response = await getPendingsReviewsByProduct({
      idBrand: 'calvin-klein-reviews',
      idProduct: 'S-232F56'
      limit:5,
      lastKey: lastKey
  })
  lastKey=result.data.nextKey
  console.log(response.data)
  `,implement2:`
  POST https://reviews-axo.wallia.io/getPendingReviewsByProduct
  HEADER Authorization:'bearer [acessToken]'
  BODY {
    idBrand: 'calvin-klein-reviews',
    idProduct: 'S-232F56'
    limit:5,
    lastKey: lastKey
  }
`}

const getReviewsByUserDoc= {
  description:`Este metodo le permite obtener una lista de reviews pendientes respecto a un usuario en especifico.
  La función recibe un identificador de brand, de producto y de usuario, su consulta siempre sera limitada a traer 
  el registro mas reciente agregado, por lo que no requerira establecer un parametro de limite.
  Esta función no hace distinción entre los estados de review. 
  No se requiere proporcionar una llave de indexación, los resultados son filtrados mediante la composicion product+usuario.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, idUser]`,
  paramsOpcional:"Parametros Opcionales: Ninguno",
  implement1:`
    const getReviewsByUser = firebaseConfig.functions().httpsCallable('getReviewsByUser')
    getReviewsByUser({
      idBrand:'guess-reviews',
      idProduct:'SUPERSTARS',
      idUser:'-JHKHD-2W3DS-'
    }).then((result) => {
      console.log(result.data)
    })
  `,implement2:`
  POST https://reviews-axo.wallia.io/getReviewsByUser
  HEADER Authorization:'bearer [acessToken]'
  BODY {
    idBrand:'guess-reviews',
    idProduct:'SUPERSTARS',
    idUser:'-JHKHD-2W3DS-'
  }
`}

const deleteReviewDoc = {
  description:`Este metodo le permite borrar permanentemente un review respecto a un producto.
  La función recibe un identificador de brand, de producto y de review.`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, idReview]`,
  paramsOpcional:"Parametros Opcionales: Ninguno",
  implement1:`
    let deleteReview = firebaseConfig.functions().httpsCallable('deleteReview')
    const response = await deleteReview({
      idBrand:'calvin-klein-reviews',
      idProduct:'ck-ert23',
      idReview:'JKLASK-45KL34'
    })
      
    console.log(response.data)
  `,implement2:`
  POST https://reviews-axo.wallia.io/deleteReview
  HEADER Authorization:'bearer [acessToken]'
  BODY {
    idBrand:'calvin-klein-reviews',
    idProduct:'ck-ert23',
    idReview:'JKLASK-45KL34'
  }
`}

const updateStatusReviewDoc = {
  description:`Este metodo le permite actualizar el estado de una review.
  La función recibe un identificador de brand, de producto, de review y cualquiera de los 3 valores de status [approved, notapproved, pending].`,
  paramsRequired:`Parametros Requeridos:[ idBrand, idProduct, idReview, status]`,
  paramsOpcional:"Parametros Opcionales: Ninguno",
  implement1:`
    let updateStatusReview = firebaseConfig.functions().httpsCallable('updateStatusReview')
    const response = await updateStatusReview({
      idBrand:'guess-dev',
      idProduct:'shoes-l34',
      idReview:'JKLkslak-sdsL'
      status'approved'
    })
    console.log(response.data)
  `,implement2:`
  POST https://reviews-axo.wallia.io/updateStatusReview
  HEADER Authorization:'bearer [acessToken]'
  BODY {
      idBrand:'guess-reviews',
      idProduct:'shoes-l34',
      idReview:'JKLkslak-sdsL'
      status'approved'
  }
`}

  const calculateAverageDoc = {
    description:`Este metodo le permite obtener el promedio ranking y numero de reviews de un producto.
    La función recibe como parametros un identificador de brand y de producto.`,
    paramsRequired:`Parametros Requeridos:[ idBrand, idProduct]`,
    paramsOpcional:"Parametros Opcionales: Ninguno",
    implement1:`
      let caculateAverageByProduct = firebaseConfig.functions().httpsCallable('caculateAverageByProduct')
      const response = await caculateAverageByProduct({
        idBrand: 'taf-dev',
        idProduct: 'koLKLKSL'
      })
      console.log(response)
    `,implement2:`
    POST https://reviews-axo.wallia.io/calculateAverageByProduct
    HEADER Authorization:'bearer [acessToken]'
    BODY {
        idBrand: 'taf-reviews',
        idProduct: 'koLKLKSL'
    }
  `}
  
  const addBrandDoc = {
    description:`Este metodo le permite agregar una brand.
    La función recibe como parametros un identificador de brand, un nombre de app y una url que debera obtener al momento de crear
    una nueva base de datos realtime en firebase console.`,
    paramsRequired:`Parametros Requeridos:[ idBrand, dataBaseUrl, appName]`,
    paramsOpcional:"Parametros Opcionales: Ninguno",
    implement1:`
      let addBrand = firebaseConfig.functions().httpsCallable('addBrand')
      const response = await addBrand({
        dataBaseUrl: 'https://new-brand.net/',
        idBrand: 'new-brand',
        appName: 'guessapp'
    })
      console.log(response)
    `,implement2:`
    POST https://reviews-axo.wallia.io/addBrand
    HEADER Authorization:'bearer [acessToken]'
    BODY {
      dataBaseUrl: 'https://new-brand.net/',
      idBrand: 'new-brand',
      appName: 'guessapp'
    }
  `}

  const getSettingsBrandDoc = {
    description:`Este metodo le permite obtener la configuracion de una brand.
    La función recibe como parametros un identificador de brand.`,
    paramsRequired:`Parametros Requeridos:[ idBrand]`,
    paramsOpcional:"Parametros Opcionales: Ninguno",
    implement1:`
      let getSettingsBrand = firebaseConfig.functions().httpsCallable('getSettingsBrand');
      const response = await getSettingsBrand({
          idBrand: 'tommy-reviews',
      })
      console.log(response.data);  
    `,implement2:`
    POST https://reviews-axo.wallia.io/getSettingsBrand
    HEADER Authorization:'bearer [acessToken]'
    BODY {
        idBrand: 'tommy-reviews',
    }
  `}


  const getProductsByBrandDoc = {
    description:`Este metodo le permite obtener una lista de productos respecto una brand.
    La función recibe como parametros un identificador de brand.`,
    paramsRequired:`Parametros Requeridos:[idBrand]`,
    paramsOpcional:"Parametros Opcionales: Ninguno",
    implement1:`
      let getProductsByBrand = firebaseConfig.functions().httpsCallable('getProductsByBrand');
      const response = await getProductsByBrand({
          idBrand: 'tommy-dev',
      })
      console.log(response.data);  
    `,implement2:`
    POST https://reviews-axo.wallia.io/getSettingsBrand
    HEADER Authorization:'bearer [acessToken]'
    BODY {
        idBrand: 'tommy-reviews',
    }
  `}

export default function AdminViewComponent() {
  const classes = useStyles();

  const [idBrandAR, setIdBrandAR] = useState("");
  const [idProductAR, setProductAR] = useState("");
  const [idUserAR,setIdUserAR] = useState("")
  const [scoreAR,setScoreAR] = useState("")
  const [messageAR,setMessageAR] = useState("")
  const [userNameAR,setUserNameAR] = useState("")

  const [idBrandGR,setIdBrandGR] = useState("")
  const [idProductGR,setIdProductGR] = useState("")
  const [limitGR,setLimitGR] = useState(1)
  const [lastKeyGR,setLastKeyGR] = useState(null)

  const [idBrandGN,setIdBrandGN] = useState("")
  const [idProductGN,setIdProductGN] = useState("")
  const [limitGN,setLimitGN] = useState(1)
  const [lastKeyGN,setLastKeyGN] = useState(null)

  const [idBrandGA,setIdBrandGA] = useState("")
  const [idProductGA,setIdProductGA] = useState("")
  const [limitGA,setLimitGA] = useState(1)
  const [lastKeyGA,setLastKeyGA] = useState(null)

  const [idBrandGP,setIdBrandGP] = useState("")
  const [idProductGP,setIdProductGP] = useState("")
  const [limitGP,setLimitGP] = useState(1)
  const [lastKeyGP,setLastKeyGP] = useState(null)

  /*const [selectedDateStart, setSelectedDateStart] = React.useState(new Date('2021-06-12T21:11:54'));
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date('2021-07-12T21:11:54'));*/

  const [idBrandGRU,setIdBrandGRU] = useState("")
  const [idProductGRU,setIdProductGRU] = useState("")
  const [idUserGRU,setIdUserGRU] = useState("")


  const [idBrandDE,setIdBrandDE] = useState("")
  const [idProductDE,setIdProductDE] = useState("")
  const [idReviewDE, setIdReviewDE] = useState("")

  const [idBrandUP,setIdBrandUP] = useState("")
  const [idProductUP,setIdProductUP] = useState("")
  const [idReviewUP, setIdReviewUP] = useState("")
  const [statusUP, setStatusUP] = useState("")

  const [idBrandAB,setIdBrandAB] = useState("")
  const [dataBaseUrlAB, setDataBaseUrlAB] = useState("");
  const [appNameAB, setAppNameAB] = useState("")

  const [idBrandGS,setIdBrandGS] = useState("")

  const [idBrandAV,setIdBrandAV] = useState("")
  const [idProductAV,setIdProductAV] = useState("")

  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [name,setName] = useState("")
  const [lastName,setLastName] = useState("")

  const [idBrandGPB,setIBrandGPB] = useState("")

  const [log1,setLog1]=useState({})
  const [log2,setLog2]=useState({})
  const [log3,setLog3]=useState({})
  const [log4,setLog4]=useState({})
  const [log5,setLog5]=useState({})
  const [log6,setLog6]=useState({})
  const [log7,setLog7]=useState({})
  const [log8,setLog8]=useState({})
  const [log9,setLog9]=useState({})
  const [log10,setLog10]=useState({})
  const [log11,setLog11]=useState({})
  const [log12,setLog12]=useState({})
  const [log13,setLog13]=useState({})
  const [log14,setLog14]=useState({})


  ////////////////////////
  //ADD REVIEWS HANDLERS//
  ////////////////////////
  const handleTextFieldIdBrandAR=event => {
    console.log(event.target.value)
    setIdBrandAR(event.target.value);
  }
  const handleTextFieldIdProductAR=event => {
    console.log(event.target.value)
    setProductAR(event.target.value);
  }
  const handleTextFieldIdUserAR=event => {
    console.log(event.target.value)
    setIdUserAR(event.target.value);
  }
  const handleTextFieldScoreAR=event => {
    console.log(event.target.value)
    setScoreAR(event.target.value);
  }
  const handleTextFieldMessageAR=event => {
    console.log(event.target.value)
    setMessageAR(event.target.value);
  }
  const handleTextFieldUserNameAR=event => {
    console.log(event.target.value)
    setUserNameAR(event.target.value);
  }

  ////////////////////////
  //GET REVIEWS HANDLERS//
  ////////////////////////
  const handleTextFieldIdBrandGR=event => {
    console.log(event.target.value)
    setIdBrandGR(event.target.value);
  }
  const handleTextFieldIdProductGR=event => {
    console.log(event.target.value)
    setIdProductGR(event.target.value);
  }
  const handleTextFieldLimitGR=event => {
    console.log(event.target.value)
    setLimitGR(parseInt(event.target.value));
  }


  /////////////////////////////////////
  //GET NOT APPROVED REVIEWS HANDLERS//
  /////////////////////////////////////
  const handleTextFieldIdBrandGN=event => {
    console.log(event.target.value)
    setIdBrandGN(event.target.value);
  }
  const handleTextFieldIdProductGN=event => {
    console.log(event.target.value)
    setIdProductGN(event.target.value);
  }
  const handleTextFieldLimitGN=event => {
    console.log(event.target.value)
    setLimitGN(parseInt(event.target.value));
  }

  /////////////////////////////////////
  //GET APPROVED REVIEWS HANDLERS    //
  /////////////////////////////////////
  const handleTextFieldIdBrandGA=event => {
    console.log(event.target.value)
    setIdBrandGA(event.target.value);
  }
  const handleTextFieldIdProductGA=event => {
    console.log(event.target.value)
    setIdProductGA(event.target.value);
  }
  const handleTextFieldLimitGA=event => {
    console.log(event.target.value)
    setLimitGA(parseInt(event.target.value));
  }

  /////////////////////////////////////
  //GET PENDING REVIEWS HANDLERS    //
  /////////////////////////////////////
  const handleTextFieldIdBrandGP=event => {
    console.log(event.target.value)
    setIdBrandGP(event.target.value);
  }
  const handleTextFieldIdProductGP=event => {
    console.log(event.target.value)
    setIdProductGP(event.target.value);
  }
  const handleTextFieldLimitGP=event => {
    console.log(event.target.value)
    setLimitGP(parseInt(event.target.value));
  }

  //////////////////////////////////
  // HANDLERS GET REVIEWS BY USER //
  //////////////////////////////////
  const handleTextFieldIdBrandGRU = event =>{
    console.log(event.target.value)
    setIdBrandGRU(event.target.value);
  }
  const handleTextFieldIdProductGRU = event =>{
    console.log(event.target.value)
    setIdProductGRU(event.target.value);
  }
  const handleTextFieldIdUserGRU = event =>{
    console.log(event.target.value)
    setIdUserGRU(event.target.value);
  }
  //////////////////////
  //    DELETE REVIEW //
  //////////////////////
  const handleTextFieldIdBrandDE = event =>{
    setIdBrandDE(event.target.value)
  }
  const handleTextFieldIdProductDE=event => {
    console.log(event.target.value)
    setIdProductDE(event.target.value);
  }
  const handleTextFieldIdReviewDE=event => {
    console.log(event.target.value)
    setIdReviewDE(event.target.value);
  }

  ///////////////////////////////////
  // HANDLERS UPDATE STATUS REVIEW //
  ///////////////////////////////////
  const handleTextFieldIdBrandUP=event => {
    console.log(event.target.value)
    setIdBrandUP(event.target.value);
  }
  const handleTextFieldIdProductUP=event => {
    console.log(event.target.value)
    setIdProductUP(event.target.value);
  }
  const handleTextFieldIdReviewUP=event => {
    console.log(event.target.value)
    setIdReviewUP(event.target.value);
  }
  const handleTextFieldStatusUP=event => {
    console.log(event.target.value)
    setStatusUP(event.target.value);
  }

  ////////////////////////////////
  // HANDLERS CALCULATE AVERAGE //
  ////////////////////////////////
  const handleTextFieldIdBrandAV = event =>{
    setIdBrandAV(event.target.value)
  }
  const handleTextFieldIdProductAV = event =>{
    setIdProductAV(event.target.value)
  }

  //////////////////////////////////////
  //HANDLERS FOR GET PRODUCTS BY BRAND //
  ///////////////////////////////////////
  const handleTextFieldIdBrandGPB= event =>{
    setIBrandGPB(event.target.value)
  }

  ////////////////////////////////
  // HANDLERS ADD BRAND         //
  ////////////////////////////////
  const handleTextFieldIdBrandAB = event =>{
    setIdBrandAB(event.target.value)
  }
  const handleTextFieldDataBaseUrlAB=event => {
    console.log(event.target.value)
    setDataBaseUrlAB(event.target.value);
  }
  const handleTextFieldAppNameAB=event => {
    console.log(event.target.value)
    setAppNameAB(event.target.value);
  }

  ////////////////////////////////
  // HANDLERS ADD BRAND         //
  ////////////////////////////////
  const handleTextFieldIdBrandGS = event =>{
    setIdBrandGS(event.target.value)
  }

  ///////////////////////////////
  //HANDLERS FOR ACCOUNT MANAGER
  ///////////////////////////////
  const handleTextFieldEmail = event =>{
    setEmail(event.target.value)
  }
  const handleTextFieldPassword = event =>{
    setPassword(event.target.value)
  }
  const handleTextFieldName = event =>{
    setName(event.target.value)
  }
  const handleTextFieldLastName = event =>{
    setLastName(event.target.value)
  }

  ///////////////////////
  // ADD REVIEW FUNCTION
  ///////////////////////
  function AddReview() {
    /*let addReview = firebaseConfig.functions().httpsCallable('addReview');
    addReview({
        idBrand: idBrandAR,
        idProduct: idProductAR,
        idUser:idUserAR,
        userName:userNameAR,
        score:      scoreAR,
        message:    messageAR,
        deviceInfo: {
            coords: {
                latitude:   17.108601,
                longitude:  -95.3018286
            },
            osVersion:      "Android",
            buildVersion:   "6.0",
            modelName:      "Samsung Note 4",
            deviceID:       "qa-23440"
        }
    }).then((result)=> {
        setLog1(result);
    }).catch((error)=> {
      setLog1(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/addReview",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand: idBrandAR,
        idProduct: idProductAR,
        idUser:idUserAR,
        userName:userNameAR,
        score:      scoreAR,
        message:    messageAR,
        deviceInfo: {
            coords: {
                latitude:   17.108601,
                longitude:  -95.3018286
            },
            osVersion:      "Android",
            buildVersion:   "6.0",
            modelName:      "Samsung Note 4",
            deviceID:       "qa-23440"
        }
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        setLog1(result);
      },(error) => {
        console.log(error)
        setLog1(error)
    })
  }

  //////////////////////////////////////////
  // GET REVIEWS BY PRODUCT FUNCTIONS //
  //////////////////////////////////////////               
  function GetReviewsByProduct(){
      /*let getReviewsByProduct = firebaseConfig.functions().httpsCallable('getReviewsByProduct');
      getReviewsByProduct({
          idBrand:    idBrandGR,
          idProduct:  idProductGR,
          limit:      limitGR,
          lastKey: lastKeyGR
      }).then((result)=> {
        console.log(result)
          setLastKeyGR(result.data.nextKey)
          setLog2(result);
      }).catch((error)=> {
          setLog2(error);   
      })*/

    fetch("https://reviews-axo.wallia.io/getReviewsByProduct",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:    idBrandGR,
        idProduct:  idProductGR,
        limit:      limitGR,
        lastKey: lastKeyGR
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        setLastKeyGR(result.data.nextKey)
        setLog2(result);
      },(error) => {
        console.log(error)
        setLog2(error)
    })
  }
  function GetNotApprovedReviewsByProduct(){
      /*let getNotApprovedReviewsByProduct = firebaseConfig.functions().httpsCallable('getNotApprovedReviewsByProduct');
      getNotApprovedReviewsByProduct({
          idBrand:idBrandGN,
          idProduct: idProductGN,
          limit:limitGN,
          lastKey: lastKeyGN
      }).then((result)=> {
        console.log(result)
          setLastKeyGN(result.data.nextKey)
          setLog3(result);
      }).catch((error)=> {
          setLog3(error);   
      })*/

      fetch("https://reviews-axo.wallia.io/getNotApprovedReviewsByProduct",{
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
        },
        body: JSON.stringify({
          idBrand:idBrandGN,
          idProduct: idProductGN,
          limit:limitGN,
          lastKey: lastKeyGN
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          setLastKeyGN(result.data.nextKey)
          setLog3(result);
        },(error) => {
          console.log(error)
          setLog3(error)
      })
  }
  function GetApprovedReviewsByProduct(){
    /*let getApprovedReviewsByProduct= firebaseConfig.functions().httpsCallable('getApprovedReviewsByProduct');
    getApprovedReviewsByProduct({
        idBrand:idBrandGA,
        idProduct: idProductGA,
        limit:limitGA,
        lastKey: lastKeyGA
    }).then((result)=> {
      console.log(result)
        setLastKeyGA(result.data.nextKey)
        setLog4(result);
    }).catch((error)=> {
      setLog4(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/getApprovedReviewsByProduct",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandGA,
        idProduct: idProductGA,
        limit:limitGA,
        lastKey: lastKeyGA
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        setLastKeyGA(result.data.nextKey)
        setLog4(result);
      },(error) => {
        console.log(error)
        setLog4(error)
    })
  }

  function GetPendingReviewsByProduct(){
    /*let getPendingReviewsByProduct= firebaseConfig.functions().httpsCallable('getPendingReviewsByProduct');
    getPendingReviewsByProduct({
        idBrand:idBrandGP,
        idProduct: idProductGP,
        limit:limitGP,
        lastKey: lastKeyGP
    }).then((result)=> {
      console.log(result)
        setLastKeyGP(result.data.nextKey)
        setLog5(result);
    }).catch((error)=> {
        setLog5(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/getPendingReviewsByProduct",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandGP,
        idProduct: idProductGP,
        limit:limitGP,
        lastKey: lastKeyGP
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        setLastKeyGP(result.data.nextKey)
        setLog5(result);
      },(error) => {
        console.log(error)
        setLog5(error)
    })
  }

  //////////////////////////////////
  // GET REVIEWS BY USER FUNCTION //
  //////////////////////////////////
  function GetReviewsByUser(){
    /*const getReviewsByUser = firebaseConfig.functions().httpsCallable('getReviewsByUser')
    getReviewsByUser({
      idBrand:idBrandGRU,
      idProduct:idProductGRU,
      idUser:idUserGRU
    }).then((result) => {
      console.log(result)
      setLog6(result)
    }).catch(error => {
      setLog6(error)
    })*/
    fetch("https://reviews-axo.wallia.io/getReviewsByUser",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandGRU,
        idProduct:idProductGRU,
        idUser:idUserGRU
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog6(result)
      },(error) => {
        console.log(error)
        setLog6(error)
    })
  }

  ////////////////////////////
  // DELETE REVIEW FUNCTION //
  ////////////////////////////
  function DeleteReview(){
    /*let deleteReview = firebaseConfig.functions().httpsCallable('deleteReview');
    deleteReview({
        idBrand:idBrandDE,
        idProduct:idProductDE,
        idReview:idReviewDE,
    }).then((result)=> {
        console.log(result)
        setLog7(result);
    }).catch((error)=> {
        setLog7(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/deleteReview",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandDE,
        idProduct:idProductDE,
        idReview:idReviewDE,
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog7(result)  
      },(error) => {
        console.log(error)
        setLog7(error) 
    })

  }

  ///////////////////////////////////
  // UPDATE STATUS REVIEW FUNCTION //
  ///////////////////////////////////
  function UpdateStatusReview(){
    /*let updateStatusReview = firebaseConfig.functions().httpsCallable('updateStatusReview');
    updateStatusReview({
      idBrand: idBrandUP,
      idProduct:idProductUP,
      idReview:idReviewUP,
      status:statusUP
    }).then((result)=> {
      console.log(result)
      setLog8(result);              
    }).catch((error)=> {
      setLog8(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/updateStatusReview",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand: idBrandUP,
        idProduct:idProductUP,
        idReview:idReviewUP,
        status:statusUP
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog8(result)  
      },(error) => {
        console.log(error)
        setLog8(error) 
    })
  }

  ///////////////////////////////////
  // UPDATE STATUS REVIEW FUNCTION //
  ///////////////////////////////////
  function CalculateAverage(){
    /*let caculateAverageByProduct = firebaseConfig.functions().httpsCallable('caculateAverageByProduct');
    caculateAverageByProduct({
      idBrand: idBrandAV,
      idProduct: idProductAV
    }).then((result)=> {
      console.log(result)
      setLog9(result)
    }).catch((error)=> {
      setLog9(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/calculateAverageByProduct",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand: idBrandAV,
        idProduct: idProductAV
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog9(result)  
      },(error) => {
        console.log(error)
        setLog9(error) 
    })

  }

  function AddBrand(){
    /*let addBrand = firebaseConfig.functions().httpsCallable('addBrand');
    addBrand({
        dataBaseUrl: dataBaseUrlAB,
        idBrand: idBrandAB,
        appName: appNameAB
    }).then((result)=> {
      console.log(result)
      setLog10(result);
    }).catch((error)=> {
      setLog10(error);   
    })*/
    fetch("https://reviews-axo.wallia.io/addBrand",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        dataBaseUrl: dataBaseUrlAB,
        idBrand: idBrandAB,
        appName: appNameAB
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog10(result)  
      },(error) => {
        console.log(error)
        setLog10(error)
    })
  }

  function GetSettingsBrand(){

    /*let getSettingsBrand = firebaseConfig.functions().httpsCallable('getSettingsBrand');
    getSettingsBrand({
        idBrand: idBrandGS,
    }).then((result)=> {
      console.log(result)
      setLog11(result);              
    }).catch((error)=> {
      setLog11(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/getSettingsBrand",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandGS,
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog11(result)  
      },(error) => {
        console.log(error)
        setLog11(error) 
    })
  }


  function CreateDeveloperAccount(){
    let createDeveloperAccount = firebaseConfig.functions().httpsCallable('createDeveloperAccount');
    createDeveloperAccount({
      email: email,
      password:password,
      name:name,
      lastName:lastName
    }).then((result)=> {
      console.log(result);   
      setLog13(result)            
    }).catch((error)=> {
      console.log(error);
      setLog13(error)    
    })
  }

  function GenerateAccessToken(){
    let generateAccessToken = firebaseConfig.functions().httpsCallable('generateAccessToken');
    generateAccessToken().then((result)=> {
        console.log(result);  
        setLog14(result)           
    }).catch((error)=> {
        console.log(error);
        setLog14(error)     
    })
  }

  function GetProductsByBrand(){
    /*let getProductsByBrand = firebaseConfig.functions().httpsCallable('getProductsByBrand');
    getProductsByBrand({
      idBrand: idBrandGPB,
    }).then((result)=> {
        console.log(result);  
        setLog12(result.data)            
    }).catch((error)=> {
        console.log(error);   
    })*/

    fetch("https://reviews-axo.wallia.io/getProductsByBrand",{
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1lY29tbWVyY2UiLCJhdWQiOiJncnVwby1heG8tZWNvbW1lcmNlIiwiYXV0aF90aW1lIjoxNjI0OTkzMTU4LCJ1c2VyX2lkIjoiYUpCdUZTYTNZclhLVXdTbm5TcDRKRVJsVFZKMiIsInN1YiI6ImFKQnVGU2EzWXJYS1V3U25uU3A0SkVSbFRWSjIiLCJpYXQiOjE2MjQ5OTMxNTgsImV4cCI6MTYyNDk5Njc1OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GP8tx4CsgA-GRyHlk8Wp_fpSBy3Xh8j-8tqAXRjCaKgxecZnKkdUQzUyZFneplMoghrUMHHXgp090XZYSY2TLm30cSxlCwxHYjXlnyZLV1CGWqcQMv6HhzeZKjRD5R10T-3LhxYTjT799mrXdHzcH1ZFDxyb7nWefV7D_wwyxtz2nSYNjVd_h9j3UvRs-891rPCbXU9QitejVoMioLmdlwn618BZmpVbIgwUHITz53saXwI1mA7M3tGewbZPhbK3Mg9X1ZQJCGcqJ18cHpRERX93yxxjRNpFnCM21Gye4mU8s4XsGVHz7PhkW1a8j7fVZjnXwr9AG3oLULE8hkS04g',
      },
      body: JSON.stringify({
        idBrand:idBrandGPB,
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);  
        setLog12(result)  
      },(error) => {
        console.log(error)
        setLog12(error)
    })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div style={{margin:5}}>
                <Typography variant="h4">
                  Getting Started
                </Typography>
              </div>
              <div style={{margin:5}}>
                <Typography variant="caption" paragraph  align="left"variant="body2" gutterBottom>
                  reviews-axo.wallia.io Es un servicio de recopilación de reseñas para productos Calvin Klein, GUESS, TAF y Tommy Hilfiger.
                  El servicio esta compuesto por 3 modulos distintos, modulo para agregar reseñas a un producto de una marca en especifico, 
                  modulo para administrar reseñas por producto y Brand, y un modulo de administración para API 
                  que se encarga de gestionar los recursos y accesos a la base de datos.

                  Cada Brand tiene una base de datos indenficada por un idBrand unico de pendiendo del ambiente de trabajo.
                </Typography>
                <Typography variant="caption" paragraph  align="left"variant="body2" gutterBottom>
                  <pre style={{ fontFamily: 'inherit'}}>{`
                  Para el ambiente de producción los IdBrand son:

                    1.- calvin-klein-reviews
                    2.- taf-reviews
                    3.- guess-reviews
                    4.- tommy-hilfiger-reviews`
                  }</pre>
                </Typography>
                <Typography variant="caption" paragraph  align="left"variant="body2" gutterBottom>
                  Para cada petición request sera necesario enviar como minimo un idBrand y token de authorización que podras obtener en la sección generar token.
                  Existen 4 iFrames: TAF, GUESS, CalvinKlein y Tommy Hilfiger los cuales estan disponibles en las siguientes urls.
                </Typography>


                <Typography variant="caption" paragraph  align="left"variant="body2" gutterBottom >
                  <pre style={{ fontFamily: 'inherit'}}>{`           
                  Para el ambiente de producción las urls asignadas son:

                    1.- https://reviews-axo.wallia.io/calvinklein
                    2.- https://reviews-axo.wallia.io/taf
                    3.- https://reviews-axo.wallia.io/guess
                    4.- https://reviews-axo.wallia.io/tommyhilfiger`
                  }</pre>
                </Typography>
                <Typography variant="caption" paragraph  align="left"variant="body2" gutterBottom>
                  Para poder ver el contenido de cada iframe debera proporcionar un idBrand, IdProduct, IdUser, userName y un token de Authorización. Tambien debera configurar sus reglas cors para permitir peticiones cruzadas y evitar errores en la implementación.
                </Typography>
              </div>
            </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                ADD REVIEWS METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdBrandAR}
              size="small"
              id="idBrand"
              label="idBrand"
              name="idBrand"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductAR}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdUserAR}
              size="small"
              id="idUser"
              label="idUser"
              name="idUser"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldScoreAR}
              size="small"
              id="score"
              label="score"
              name="score"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldMessageAR}
                size="small"
                id="message"
                label="message"
                name="message"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldUserNameAR}
                size="small"
                id="username"
                label="userName"
                name="userName"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={AddReview} doc={addReviewDoc} data={log1}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET REVIEWS BY PRODUCT METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGR}
                size="small"
                id="idBrand"
                label="idBrand"
                name="idBrand"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductGR}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              label="limit"
              value={limitGR}
              fullWidth
              size="small"
              onChange={handleTextFieldLimitGR}
              name="limit"
              id="limit"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetReviewsByProduct} doc={getReviewsByProductDoc}  data={log2}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET NOT APPROVED REVIEWS BY PRODUCT METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGN}
                size="small"
                id="idBrand"
                label="idBrand"
                name="idBrand"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductGN}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              label="limit"
              value={limitGN}
              fullWidth
              size="small"
              onChange={handleTextFieldLimitGN}
              name="limit"
              id="limit"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetNotApprovedReviewsByProduct} doc={getNotApprovedReviewsByProductDoc}  data={log3}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET APPROVED REVIEWS BY PRODUCT METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGA}
                size="small"
                id="idBrand"
                label="idBrand"
                name="idBrand"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductGA}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              label="limit"
              value={limitGA}
              fullWidth
              size="small"
              onChange={handleTextFieldLimitGA}
              name="limit"
              id="limit"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetApprovedReviewsByProduct} doc={getApprovedReviewsByProductDoc}  data={log4}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET PENDING REVIEWS BY PRODUCT METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGP}
                size="small"
                id="idBrand"
                label="idBrand"
                name="idBrand"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductGP}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              label="limit"
              value={limitGP}
              fullWidth
              size="small"
              onChange={handleTextFieldLimitGP}
              name="limit"
              id="limit"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetPendingReviewsByProduct} doc={getPendingReviewsByProductDoc} data={log5}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET REVIEWS BY USER METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGRU}
                size="small"
                id="idBrandGRU"
                label="idBrand"
                name="idBrandGRU"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductGRU}
              size="small"
              id="idProductGRU"
              label="idProduct"
              name="idProductGRU"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdUserGRU}
              size="small"
              id="idUserGRU"
              label="idUser"
              name="idUserRU"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetReviewsByUser} doc={getReviewsByUserDoc} data={log6}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                DELETE REVIEW METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandDE}
                size="small"
                id="idBrand"
                label="idBrand"
                name="idBrand"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductDE}
              size="small"
              id="idProduct"
              label="idProduct"
              name="idProduct"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              label="IdReview"
              fullWidth
              size="small"
              onChange={handleTextFieldIdReviewDE}
              name="IdReview"
              id="IdReview"
            />
          </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={DeleteReview} doc={deleteReviewDoc} data={log7}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                UPDATE STATUS REVIEW METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandUP}
                size="small"
                id="brandUP"
                label="idBrand"
                name="brandUP"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductUP}
              size="small"
              id="productUP"
              label="idProduct"
              name="productUP"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                label="idReview"
                fullWidth
                size="small"
                onChange={handleTextFieldIdReviewUP}
                name="reviewUP"
                id="reviewUP"
              />
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                label="approved, notapproved, pending"
                fullWidth
                size="small"
                onChange={handleTextFieldStatusUP}
                name="statusUP"
                id="statusUP"
              />
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={UpdateStatusReview} doc={updateStatusReviewDoc} data={log8}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                CALCULATE AVERAGE METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandAV}
                size="small"
                id="idBrandAV"
                label="idBrand"
                name="idBrandAV"
                fullWidth
              />
            </div>
            <div style={{margin:5}}>
            <TextField
              variant="outlined"
              required
              onChange={handleTextFieldIdProductAV}
              size="small"
              id="idProductAV"
              label="idProduct"
              name="idProductAV"
              fullWidth
            />
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={CalculateAverage} doc={calculateAverageDoc} data={log9}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET PRODUCTS BY BRAND
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGPB}
                size="small"
                id="idBrandGS"
                label="idBrand"
                name="idBrandGS"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetProductsByBrand} doc={getProductsByBrandDoc} data={log12}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                ADD BRAND METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandAB}
                size="small"
                id="idBrandAB"
                label="idBrand"
                name="idBrandAB"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldDataBaseUrlAB}
                size="small"
                id="databaseurl"
                label="Database URL"
                name="databaseurl"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldAppNameAB}
                size="small"
                id="appNameAB"
                label="App name"
                name="nameAB"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={AddBrand} doc={addBrandDoc} data={log10}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography variant="h4">
                GET BRAND SETTINGS METHOD
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldIdBrandGS}
                size="small"
                id="idBrandGS"
                label="idBrand"
                name="idBrandGS"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <AccordionComponent onClickAction={GetSettingsBrand} doc={getSettingsBrandDoc} data={log11}></AccordionComponent>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography>
                Add developer Account
              </Typography>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldEmail}
                size="small"
                id="email"
                label="email"
                name="email"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldPassword}
                size="small"
                id="password"
                label="password"
                name="password"
                fullWidth/>
            </div>

            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldName}
                size="small"
                id="name"
                label="name"
                name="name"
                fullWidth/>
            </div>

            <div style={{margin:5}}>
              <TextField
                variant="outlined"
                required
                onChange={handleTextFieldLastName}
                size="small"
                id="lastName"
                label="lastName"
                name="lastName"
                fullWidth/>
            </div>
            <div style={{margin:5}}>
              <Button variant="contained" onClick={CreateDeveloperAccount}color="primary">Create Developer Account</Button>
            </div>
            <main className={clsx(classes.content)}>
              <Typography variant="caption" paragraph align="left" component={'span'}>
                response:
                
                {JSON.stringify(log13)}
                
              </Typography>
            </main> 
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{margin:5}}>
              <Typography>
                GET ACCESS TOKEN
              </Typography>
            </div>
          
            <div style={{margin:5}}>
              <Button variant="contained" onClick={GenerateAccessToken} color="primary">Get Access Token</Button>
            </div>
            <main className={clsx(classes.content)}>
              <Typography variant="caption" paragraph align="left" component={'span'}>
                response
                
                {JSON.stringify(log14)}
                
              </Typography>
            </main> 
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}