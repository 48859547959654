import React, { useState, useEffect } from 'react';
import { Switch, Redirect,Route } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import TextField from '@material-ui/core/TextField'
import PrivateRoute from './PrivateRoute';
import {
    AdminView,
    SignInView,
    AddReview,
    AverageView,
    ItemsList,
    LoadTestView
} from './views';

const TestViewIFrame = ()=>{
  const [idBrandURL,setIdBrandURL]=useState('https://reviews-axo.wallia.io/calvinklein?idBrand=calvin-klein-reviews&idProduct=D1FGH123&idUser=TEST-USER&userName=Francisco')

  const handleTextFieldURL=event => {
    console.log(event.target.value)
    setIdBrandURL(event.target.value);
  }

  return (
    <div>
    <div style={{margin:20}}>
        <TextField
          defaultValue={idBrandURL}
          variant="outlined"
          required
          onChange={handleTextFieldURL}
          size="small"
          id="idBrand"
          label="idBrand"
          name="idBrand"
          fullWidth
        />
      </div>
    <div style={{marginTop:10,
    justifyContent: "center",display: "flex",
    alignItems: "center"}}>
      
     
      <iframe id="if" src={idBrandURL} height="500" width="500" title="Iframe Example"></iframe>
      </div>
    </div>
  )
}

const ViewIFrame = ()=>{

  const [brand,setBrand] = useState(null)
  const [product,setProduct] = useState(null)
  const [user,setUser] = useState(null)
  const [userName,setUserName] = useState(null)
  const [loading,setLoading] = useState(true)

  function getParamValue(paramName)
  {
    var url = window.location.search.substring(1); //get rid of "?" in querystring
    var qArray = url.split('&'); //get key-value pairs
    for (var i = 0; i < qArray.length; i++) 
    {
        var pArr = qArray[i].split('='); //split key and value
        console.log(pArr)
        if (pArr[0] == paramName) 
            return pArr[1]; //return value
    }
  }

  useEffect(()=>{
    console.info('Looking for params...')
    setBrand(getParamValue('idBrand'))
    setProduct(getParamValue('idProduct'))
    setUser(getParamValue('idUser'))
    setUserName(getParamValue('userName'))
    setLoading(false)
    console.log('running')
  },[])

  return (
    <div>
      {loading?
      <></>
      :
      <>
      <AverageView idBrand={brand} idProduct={product}></AverageView>
      <AddReview idBrand={brand} idProduct={product} idUser={user} userName={userName}></AddReview>
      <ItemsList idBrand={brand} idProduct={product}></ItemsList>
      </>
      }
    </div>
  )
}

const productionDBS=['calvin-klein-reviews','guess-reviews','taf-reviews','tommy-hilfiger-reviews']
let currentDBS=productionDBS

const Routes = (props) => {

  const [on,setOn]=useState(false)

  return (
    <Switch>
      <Redirect
        exact={true} 
        from="/"
        to="/functions-docs"
      />
      <PrivateRoute
        component={
          TestViewIFrame
        }
        
        exact={true} 
        layout={MainLayout}
        path='/iframe-testing'
        on={setOn}
      />

      <PrivateRoute
        component={AdminView}
        exact={true} 
        layout={MainLayout}
        path="/functions-docs"
        on={setOn}
      />   
      
      <PrivateRoute
        component={LoadTestView}
        brands={currentDBS} 
        exact={true} 
        layout={MainLayout}
        path="/load-testing"
        on={setOn}
      />  
      <RouteWithLayout
        component={SignInView}
        exact={true} 
        layout={MinimalLayout}
        path='/sign-in'
      />

      <Route
        exact={true}
        path='/calvinklein'
        children={<ViewIFrame/>}
      />
      <Route
        exact={true}
        path='/guess'
        children={<ViewIFrame/>}
      />
      <Route
        exact={true}
        path='/taf'
        children={<ViewIFrame />}
      />
      <Route
        exact={true}
        path='/tommyhilfiger'
        children={<ViewIFrame />}
      />
    </Switch>
  );
};

export default Routes;